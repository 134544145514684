import styled from "@emotion/styled"
import Img from "gatsby-image"

export const GalleryGrid = styled.section`
  display: grid;
  grid-template-columns: repeat(1, auto);
  grid-auto-rows: 1fr;
  grid-gap: 1rem;
  padding: 1rem;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, auto);
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, auto);
  }
`

export const GalleryItem = styled(Img)``
