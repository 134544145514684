import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { css } from "@emotion/core"

import { PostHero, PostTitle } from "../templates/post/post-styles"
import {
  GalleryGrid,
  GalleryItem,
} from "../components/gallery/gallery-grid-styles"

const GalleryPage = ({ data }) => {
  const images = data.allSanityGallery.edges[0].node.slides

  return (
    <>
      <SEO title="Gallery" />
      <PostHero>
        <PostTitle
          css={css`
            text-transform: capitalize;
          `}
        >
          Gallery
        </PostTitle>
      </PostHero>
      <GalleryGrid>
        {images.map((slide, index) => (
          <GalleryItem
            className="anim-in-up"
            key={index}
            fluid={slide.image.asset.fluid}
          />
        ))}
      </GalleryGrid>
    </>
  )
}

export default GalleryPage

export const query = graphql`
  {
    allSanityGallery {
      edges {
        node {
          slides {
            image {
              asset {
                fluid(maxWidth: 960) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
